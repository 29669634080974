import Heading from 'src/components/Heading/Heading';
import HTMLReactParser from 'html-react-parser';
import Image from 'next/image';

type HomeSafeHandsProps = {
    data: {
        label: string;
        description: string;
        image: {
            src: string;
            width: number;
            height: number;
        };
    }[];
};

const HomeSafeHands = ({ data }: HomeSafeHandsProps) => {
    return (
        <div className="container">
            <Heading centered={true} level={'h2'} className={'mb-4'}>
                You&apos;re in safe hands
            </Heading>
            <p className="mb-2 text-center md:mx-auto md:max-w-xl">
                Tens of thousands of people have trusted Mosh with their
                healthcare needs and we take pride in providing the best
                service.
            </p>
            <div className="flex snap-x snap-mandatory flex-nowrap space-x-4 overflow-x-auto py-8 md:grid md:grid-cols-2 md:gap-x-4 md:gap-y-4 md:space-x-0 md:overflow-visible lg:grid-cols-4">
                {data.length &&
                    data.map((e, i) => {
                        return (
                            <div
                                className="w-2/3 shrink-0 snap-start rounded-xl bg-white p-4 text-center shadow-md md:w-full"
                                key={i}
                            >
                                <Image
                                    src={e.image.src}
                                    width={e.image.width}
                                    height={e.image.height}
                                    alt={e.label}
                                    className={
                                        'mx-auto block w-[60px] text-center'
                                    }
                                />
                                <p className="mb-2 font-bolder">{e.label}</p>
                                <p className="mb-4">
                                    {HTMLReactParser(e.description)}
                                </p>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};

export default HomeSafeHands;
