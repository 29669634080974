'use client';

import { faStar } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef, useState } from 'react';

type Testimonial = {
    title: string;
    rating?: number;
    content: string;
    author: {
        name: string;
        state?: string;
    };
};

export type TestimonialsProps = {
    title?: string;
    description?: string;
    className?: string;
    buttonClassName?: string;
    buttonStyle?: string;
    list: Testimonial[];
    showStarRating?: boolean;
};

const Testimonials = ({
    title = 'Trusted by over 80,000 Aussies',
    ...props
}: TestimonialsProps) => {
    const scrollRef = useRef<HTMLDivElement>(null);
    const [scrollPositionInitialised, setScrollPositionInitialised] =
        useState(false);

    // Center the scroll position on load
    useEffect(() => {
        if (scrollRef.current && !scrollPositionInitialised) {
            scrollRef.current.scrollLeft =
                (scrollRef.current.scrollWidth -
                    scrollRef.current.clientWidth) /
                2;

            setScrollPositionInitialised(true);
        }
    }, [scrollPositionInitialised, scrollRef]);

    return (
        <section className="bg-white py-16">
            <div className="flex flex-col gap-12">
                {/* Header */}
                <div className="container flex flex-col items-center gap-2 text-center text-green-dark">
                    <h2>{title}</h2>
                    {props.description ? <p>{props.description}</p> : null}
                </div>
                {/* Cards */}
                <div
                    className="flex flex-row items-stretch gap-4 overflow-x-scroll pb-4"
                    ref={scrollRef}
                >
                    {(props?.list || []).map((item, index) => (
                        <article
                            key={index}
                            className="flex min-w-64 flex-col gap-4 rounded-2xl bg-green-dark text-white shadow-md md:min-w-[400px]"
                        >
                            <div className="flex grow flex-col gap-4 p-4">
                                <h4>{item.title}</h4>
                                {!item.rating ||
                                props.showStarRating === false ? null : (
                                    <div className="flex flex-row items-start">
                                        {[...Array(item.rating)].map((e, i) => (
                                            <FontAwesomeIcon
                                                key={i}
                                                icon={faStar}
                                                className="text-green"
                                            />
                                        ))}
                                    </div>
                                )}
                                <p>{item.content}</p>
                            </div>
                            <div className="flex flex-row p-4">
                                <h5>{item.author.name}</h5>
                            </div>
                        </article>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Testimonials;
