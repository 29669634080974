import classNames from 'classnames';
import styles from 'src/components/PhoneFrame/phone-frame.module.scss';
import Image from 'next/image';
import MoshLogo from '../MoshLogo/MoshLogo';

const sampleMessages = [
    {
        message: 'Hey Doc!',
        image: '/images/home/messageyourdoctor/user-chat.png',
        imgAlt: '',
        time: '11:58 AM',
    },
    {
        message: 'Hi there, how can I help?',
        image: '/images/home/messageyourdoctor/doctor-chat.png',
        imgAlt: '',
        time: '12:30 PM',
    },
    {
        message: 'Just checking how often I should take my medication?',
        image: '/images/home/messageyourdoctor/user-chat.png',
        imgAlt: '',
        time: '01:15 PM',
    },
    {
        message: 'Typing...',
        image: '/images/home/messageyourdoctor/doctor-chat.png',
        imgAlt: '',
        time: '02:20 PM',
    },
];

const PhoneFrame = () => {
    return (
        <div className={classNames('relative', styles['messagedoctor'])}>
            <Image
                src={'/images/elements/iphone-frame.png'}
                alt="show doc info placeholder"
                width={1661}
                height={1429}
            />
            <div className={classNames(styles['messagedoctor--mobilescreen'])}>
                <div className={classNames(styles['message-screen-header'])}>
                    <MoshLogo />
                    <div className={classNames(styles['message-doctor-info'])}>
                        <div>
                            <p className={classNames(styles['doctor-name'])}>
                                Dr. Paul Goodridge
                            </p>
                            <p
                                className={classNames(
                                    styles['usually-responds'],
                                )}
                            >
                                Usually responds within 24 hours
                            </p>
                        </div>
                        <Image
                            className={classNames(
                                styles['message-doctor-info-more'],
                            )}
                            src={'/images/home/messageyourdoctor/menu-more.svg'}
                            alt="show doc info placeholder"
                            height={30}
                            width={10}
                        />
                    </div>
                </div>
                <div className={classNames(styles['message-screen-chat'])}>
                    <div
                        className={classNames(
                            styles['message-screen-chat--wrapper'],
                        )}
                    >
                        {sampleMessages.map((msg, i) => (
                            <PhoneFrameMessage data={msg} key={i} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

type PhoneFrameMessageProps = {
    data: {
        message: string;
        image: string;
        time: string;
    };
};

const PhoneFrameMessage = ({ data }: PhoneFrameMessageProps) => {
    return (
        <div className={classNames(styles['single-message'])}>
            <div className={classNames(styles['single-message-content'])}>
                <Image src={data.image} width={24} height={24} alt="" />
                <div className={classNames(styles['message-content'])}>
                    <p className={classNames(styles['message-text'])}>
                        {data.message}
                    </p>
                    <span className={classNames(styles['message-time'])}>
                        {data.time}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default PhoneFrame;
