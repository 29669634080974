import Heading from 'src/components/Heading/Heading';
import Image from 'next/image';
import styles from './home-supporting-our-boys.module.scss';

type HomeSupportingOurBoysProps = {
    data: {
        title: string;
        description: string;
        logos: {
            image: {
                src: string;
                width: number;
                height: number;
                alt: string;
            };
        };
    };
};

const HomeSupportingOurBoys = ({ data }: HomeSupportingOurBoysProps) => {
    return (
        <div className={styles['supporting-our-boys--wrapper']}>
            <div className={styles['wrapper']}>
                <div className={styles['header-item']}>
                    <div className="w-full place-content-end">
                        <Heading
                            level={'h2'}
                            tag={'h'}
                            className={styles['header-title']}
                        >
                            {data.title}
                        </Heading>
                        <p className={'mb-0 self-stretch'}>
                            {data.description}
                        </p>
                    </div>
                </div>
                <div className={styles['image-item']}>
                    <Image
                        src={data.logos.image.src}
                        width={data.logos.image.width}
                        height={data.logos.image.height}
                        alt={data.logos.image.alt}
                        className={styles['supporting-our-boys--logo']}
                    />
                </div>
            </div>
        </div>
    );
};

export default HomeSupportingOurBoys;
