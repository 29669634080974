import Image from 'next/image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './home.module.scss';
import classNames from 'classnames';
import { trackClick } from 'src/analytics/Events';
import EventNames from 'src/analytics/EventNames';
import { faArrowRightLong } from '@fortawesome/pro-solid-svg-icons';

type HomeServicesInfoProps = {
    data: {
        items: {
            title: string;
            description: string;
            image: {
                src: string;
                width: number;
                height: number;
            };
            services: {
                label: string;
                url: string;
            }[];
        }[];
    };
};

const HomeServicesInfo = ({ data }: HomeServicesInfoProps) => {
    return (
        <div className="container">
            <div className="grid grid-cols-1 gap-y-8 md:grid-cols-2 md:gap-x-8 lg:grid-cols-3">
                {data.items.map((e, i) => {
                    return <ServiceBlock service={e} key={i} />;
                })}
            </div>
        </div>
    );
};

type ServiceBlockProps = {
    service: {
        title: string;
        description: string;
        image: {
            src: string;
            width: number;
            height: number;
        };
        services: {
            label: string;
            url: string;
        }[];
    };
};

const ServiceBlock = ({ service }: ServiceBlockProps) => {
    const handleLinkClick = (item: { label: string; url: string }) => {
        trackClick(EventNames.CATEGORY_SUBPAGE_LINK_CLICKED, {
            label: item.label,
            destination_url: item.url,
        });
    };

    return (
        <article className="">
            <header className="mb-4 flex items-center justify-between">
                <p className="font-heading text-lg tracking-tight">
                    {service.title}
                </p>
                <Image
                    src={service.image.src}
                    width={service.image.width}
                    height={service.image.height}
                    alt={service.title}
                    className={'w-[60px]'}
                />
            </header>
            <section>
                <p className="mb-4">{service.description}</p>
                <ul>
                    {service.services.map((e, i) => {
                        return (
                            <li key={i}>
                                <a
                                    className={classNames(
                                        styles['service--link'],
                                        'flex items-center justify-between py-2 pr-4 font-bolder transition-all hover:pr-3',
                                    )}
                                    href={e.url}
                                    onClick={() => {
                                        handleLinkClick(e);
                                    }}
                                    title={e.label}
                                >
                                    {e.label}
                                    <FontAwesomeIcon
                                        icon={faArrowRightLong}
                                        className="h-8 w-8 rounded-full bg-[#F5F5F5] p-2"
                                    />
                                </a>
                            </li>
                        );
                    })}
                </ul>
            </section>
        </article>
    );
};

export default HomeServicesInfo;
